/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Timer from "../components/timer"
import dash from "../images/dash.svg"
import card from "../images/card.png"
import Tracker from "../components/tracker"

const CardWrapper = styled.div`
  padding-right: 2rem;
  padding-left: 2rem;
`

const StyledHeader = styled.b`
  font-size: 2rem;
  margin-right: 0.75rem;
`

const TitleWrapper = styled.div`
  padding-top: 1rem;
  padding-bottom: 2rem;
  padding-left: 6rem;
  @media (max-width: 769px) {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
`

const StyledCard = styled.div`
  border: 0.5rem solid #5ed0fe;
  border-radius: 2.5rem;
  position: relative;
  color: #4a4a4a;
  max-width: 100%;
  background-color: white;
`

const GridWrapper = styled.div`
  padding: 2rem;
  background-color: transparent;
`

const Controls = styled.div`
  @media (min-width: 769px) {
    background-color: #5ed0fe;
    align-items: stretch;
    display: flex;
    border-bottom-left-radius: 2rem;
    border-bottom-right-radius: 2rem;
    height: 4rem;
    padding: 0 1rem;
  }
  @media (max-width: 768px) {
    background-color: #5ed0fe;
    display: flex;
    height: 5rem;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
  }
`

const Dash = styled.img`
  width: 5rem;
  display: block;
`

const FlipCard = styled.div`
  height: 8rem;
  width: 192px;
  background-color: transparent;
  perspective: 1000px;
`

const FlipCardInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
`

const FlipCardFront = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
`

const FlipCardBack = styled.div`
  padding: 3rem 0.5rem;
  border: 0.1rem solid grey;
  border-radius: 0.5rem;
  background-color: #fefefc;
  position: absolute;
  width: 100%;
  height: 8.5rem;
  backface-visibility: hidden;
  transform: rotateY(180deg);
`

const cardsInit = Object.freeze({
  lastCard: {
    word: null,
    key: null,
    ref: null,
    face: "down",
  },
  currentCard: {
    word: null,
    key: null,
    ref: null,
    face: "down",
  },
})

class ConcentrationgameTemplate extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      cards: cardsInit,
      words: this.words,
      rand: new Date(),
      drama: false,
    }

    this.cardHandler = this.cardHandler.bind(this)
    this.timeHandler = this.timeHandler.bind(this)
    this.timeRef = React.createRef()
  }

  componentDidUpdate() {
    const currentCard = this.state.cards.currentCard
    const lastCard = this.state.cards.lastCard

    if (
      lastCard.word === currentCard.word &&
      lastCard.key !== currentCard.key
    ) {
      var soundEffect = new Audio(
        "https://hounddogreading.s3.us-east-2.amazonaws.com/game_bell.mp3"
      )
      soundEffect.play()
      setTimeout(function () {
        currentCard.ref.style.display = "none"
        lastCard.ref.style.display = "none"
      }, 1800)
      this.setState({ cards: cardsInit })
    } else {
      if (
        lastCard.ref != null &&
        currentCard.face !== "down" &&
        lastCard.face !== "down"
      ) {
        setTimeout(function () {
          currentCard.ref.style.transform = "rotateY(0deg)"
          lastCard.ref.style.transform = "rotateY(0deg)"
        }, 1800)
        this.setState({ cards: cardsInit })
      }
    }
  }

  get words() {
    const length = this.props.data.strapiConcentrationgame.Words.length
    const size = 16
    if (size > length) {
      return this.trim(
        this.shuffle(
          this.repeat(
            this.props.data.strapiConcentrationgame.Words,
            Math.ceil(size / length)
          )
        ),
        size
      )
    }
    return this.shuffle(
      this.repeat(
        this.trim(
          this.shuffle(this.props.data.strapiConcentrationgame.Words),
          Math.ceil(size / 2)
        ),
        2
      )
    )
  }

  trim(array, size) {
    if (array.length !== size) {
      return array.slice(0, size - array.length)
    }
    return array
  }

  shuffle(a) {
    for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      ;[a[i], a[j]] = [a[j], a[i]]
    }
    return a
  }

  repeat(array, times) {
    let newArray = []
    for (let i = 0; i < times; i++) {
      newArray = [...newArray, ...array]
    }
    return newArray
  }

  cardHandler(event) {
    const word = event.currentTarget.getAttribute("data-word")
    const key = event.currentTarget.getAttribute("data-key")
    const ref = event.currentTarget

    ref.style.transform = "rotateY(180deg)"

    this.setState((prevState) => ({
      cards: {
        currentCard: {
          word: word,
          key: key,
          ref: ref,
          face: "up",
        },
        lastCard: prevState.cards.currentCard,
      },
    }))
  }

  timeHandler() {
    if (this.timeRef.current.state.lastAction === "stop") {
      this.setState({ drama: true })
      setTimeout(() => {
        this.setState({
          cards: cardsInit,
          words: this.words,
          rand: new Date(),
          drama: false,
        })
      }, Math.floor(Math.random() * (2000 - 1000 + 1) + 1000))
    }
  }

  render() {
    return (
      <Layout>
        <SEO title="Concentration Game" />

        <TitleWrapper className="columns">
          <div className="column">
            <h2 className="subtitle is-4">
              <StyledHeader>
                {this.props.data.strapiConcentrationgame.Title}
              </StyledHeader>
              {this.props.data.strapiConcentrationgame.Description}&nbsp;
              <Tracker id={this.props.data.strapiConcentrationgame.id} />
              <Dash src={dash} alt="some dashes" />
            </h2>
          </div>
        </TitleWrapper>

        <div className="columns">
          <CardWrapper className="column is-full">
            <StyledCard>
              <GridWrapper>
                {this.state.drama ? (
                  <div className=" column has-text-centered">
                    <h2 className="title">Scrambling Words</h2>
                    <progress className="progress is-primary" max="100">
                      Scrambling Words
                    </progress>
                  </div>
                ) : (
                  <div className="columns is-multiline">
                    {this.state.words.map((word, key) => (
                      <div
                        className="column is-3"
                        key={`${this.state.rand}-${key}-${word}`}
                      >
                        <FlipCard>
                          <FlipCardInner
                            data-word={word}
                            data-key={key}
                            onClick={this.cardHandler}
                          >
                            <FlipCardFront>
                              <img src={card} width="192px" alt="" />
                            </FlipCardFront>
                            <FlipCardBack
                              className={
                                word.length > 10
                                  ? "flip-card-back is-size-4 has-text-weight-bold"
                                  : "flip-card-back title"
                              }
                            >
                              {word}
                            </FlipCardBack>
                          </FlipCardInner>
                        </FlipCard>
                      </div>
                    ))}
                  </div>
                )}
              </GridWrapper>
              <Controls>
                <Timer ref={this.timeRef} onClick={this.timeHandler} />
              </Controls>
            </StyledCard>
          </CardWrapper>
        </div>
      </Layout>
    )
  }
}
ConcentrationgameTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ConcentrationgameTemplate

export const query = graphql`
  query ConcentrationgameTemplate($id: String!) {
    strapiConcentrationgame(id: { eq: $id }) {
      id
      Title
      Description
      Words
      url
    }
  }
`
